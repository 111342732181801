app.controller('PostcodeCheckerController', [
                                            '$scope',
                                            'Config',
                                            'CacheFactory',
                                            '$window',
                                            'cart',
											'helper',
											'$http',
function($scope,Config,CacheFactory,$window,cart,helper,$http){
  $scope.base_url = Config.base_url;

  $scope.addToCart = function(product_ids,product_qtys,slugs){
    var products = product_ids.split("|");
    var quantities = product_qtys.split("|");
    var slugs = slugs.split("|");
    for (i = 0; i < products.length; i++) { 
        cart.add(products[i],slugs[i],quantities[i],null,null,null,'planner');
    }
  }
  
  $scope.calculateShipping = function(form){
		if(form.$valid){
			//send an ajax call to save the message and send an email notification
			$('.postcode-checker-loader-wrapper').show();
			$http({
			  method: 'GET',
			  url: Config.api_url+'quote/postcode-checker?brand='+Config.brand_key+'&delivery-postcode='+$scope.postcode+'&collection-postcode='+$scope.postcode+'&cart-total-weight=0',
			  headers:{
			  	'authorization':Config.api_key
			  }
			}).then(function(response) {
				if(response.data.delivery_zone_status=='enabled'){
				   	if(response.data.delivery_charge==0){
				   		$scope.result = 'We are pleased to confirm that we deliver to your area free of charge.';
				   	}else{
				   		$scope.result = 'We are pleased to confirm that we deliver to your area from as little as £'+response.data.delivery_charge+'.';
				   	}
				}else{
					$scope.result = 'We\'d like to provide an exact price for delivery to this postcode, please contact us.';
				}
				$('.postcode-checker-loader-wrapper').hide();

			  }, function(response) {
				$('.postcode-checker-loader-wrapper').hide();

			    console.log(response);
			});


		}
	}

}]);